<template lang="html">
  <div class="changePassword">
    <div class="box">
      <!-- <div class="list">
        <div class="left phone">
          <img src="../../assets/image/phone.png" alt="">
          <span>中国</span>
          <span class="special">(+86)</span>
        </div>
        <div class="right">
          <van-field v-model="params.phone" placeholder="手机号码" />
        </div>
      </div>
      <div class="list">
        <div class="left">
          <img src="../../assets/image/code.png" alt="">
          <span>验证码</span>
        </div>
        <div class="right">
          <div class="">
            <van-field v-model="params.smsCode" placeholder="请输入验证码" />
          </div>
          <div class="code" @click="getCode" v-if="isShowText">
            获取验证码
          </div>
          <div class="code" @click="getCode" v-else>
            {{time}}重新发送
          </div>
        </div>
      </div>-->
      <div class="list">
        <div class="left">
          <span>原密码</span>
        </div>
        <div class="right">
          <van-field v-model="params.originalPassword" type="password" placeholder="请输入新密码" />
        </div>
      </div>
      <div class="list">
        <div class="left">
          <span>新密码</span>
        </div>
        <div class="right">
          <van-field v-model="params.password" type="password" placeholder="请输入新密码" />
        </div>
      </div>
      <div class="list">
        <div class="left">
          <span>确认新密码</span>
        </div>
        <div class="right">
          <van-field v-model="params.enterPassword" type="password" placeholder="请输入确认新密码" />
        </div>
      </div>
      <div class="btn">
        <van-button type="primary" @click="confirm" style="color: #FFFFFF;" round>完成</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'changePassword',
  data() {
    return {
      time: 59,
      timeou: null,
      isShowText: true,
      params: {
        originalPassword: '',
        password: '',
        enterPassword: '',
        filed: 'originalPassword,password,enterPassword'
      }
    };
  },
  methods: {
    getCode() {
      this.isShowText = false;
      this.timeou = setInterval(() => {
        --this.time;
        if (this.time <= 0) {
          clearInterval(this.timeou);
          this.time = 59;
          this.isShowText = true;
        }
      }, 1000);
    },
    confirm() {
      if (!this.params.originalPassword) {
        this.$toast('请输入原密码');
        return;
      }
      if (!this.params.password) {
        this.$toast('请输入新密码');
        return;
      }
      if (this.params.password != this.params.enterPassword) {
        this.$toast('新密码不一致');
        return;
      }
      this.$require.HttpPost('/shop/updatePassword', this.params, { isTokn: false, isURl: true }).then(() => {
        this.$toast('修改成功,请重新登录！');
        this.$router.replace({
          path: '/login',
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.changePassword {
  padding: 30px 24px 0 24px;
  .box {
    box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
    border-radius: 30px;
    padding-bottom: 70px;
    .list {
      height: 140px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      .left {
        width: 220px;
        font-size: 28px;
        &.phone {
          position: relative;
          &:after {
            content: " ";
            width: 2px;
            height: 60px;
            background: #efefef;
            position: absolute;
            right: 0;
          }
        }
        img {
          width: 48px;
          height: 48px;
          margin-right: 15px;
        }
        span {
          color: #000000;
          &.special {
            color: #85c226;
          }
        }
      }
      .right {
        padding-left: 30px;
        display: flex;
        align-items: center;
        color: #333333;
        font-size: 28px;
        flex: 1;
        .van-field {
          flex: 1;
        }
        .code {
          display: block;
          width: 170px;
          height: 60px;
          line-height: 60px;
          background: rgba(#81b51d, 0.1);
          border-radius: 30px;
          font-size: 24px;
          color: #81b51d;
          text-align: center;
        }
      }
    }
    .btn {
      width: 468px;
      height: 96px;
      margin: 60px auto 0 auto;
    }
  }
}
</style>
